exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-qr-tsx": () => import("./../../../src/pages/app/qr.tsx" /* webpackChunkName: "component---src-pages-app-qr-tsx" */),
  "component---src-pages-app-store-tsx": () => import("./../../../src/pages/app/store.tsx" /* webpackChunkName: "component---src-pages-app-store-tsx" */),
  "component---src-pages-app-stores-tsx": () => import("./../../../src/pages/app/stores.tsx" /* webpackChunkName: "component---src-pages-app-stores-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-data-policy-js": () => import("./../../../src/pages/data-policy.js" /* webpackChunkName: "component---src-pages-data-policy-js" */),
  "component---src-pages-for-clinics-js": () => import("./../../../src/pages/for-clinics.js" /* webpackChunkName: "component---src-pages-for-clinics-js" */),
  "component---src-pages-for-patients-js": () => import("./../../../src/pages/for-patients.js" /* webpackChunkName: "component---src-pages-for-patients-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-not-fertility-clinic-js": () => import("./../../../src/pages/not-fertility-clinic.js" /* webpackChunkName: "component---src-pages-not-fertility-clinic-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

