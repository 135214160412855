module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Salve","short_name":"Salve","start_url":"/","background_color":"#8665e3","theme_color":"#8665e3","display":"minimal-ui","icon":"src/assets/fav.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"66d54a42b4b3b51178dafdb48b0670a8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de","es"],"defaultLanguage":"en","i18nextOptions":{"ns":["translation"],"defaultNS":"translation","fallbackLng":"en","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-optin/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LTQX5F1PP9"]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://98948aa9cc134b729f8770f74f3dde4c@o220829.ingest.sentry.io/5533906","sampleRate":0.7,"environment":"production"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
